import { getColorClassFromGutenberg } from 'src/utils/gutenbergUtils';

export interface ParagraphProps {
  className?: string;
  content?: string;
  innerHTML?: string;
}

const Paragraph = (props: ParagraphProps) => {
  const { innerHTML, className, content } = props;
  const html = getColorClassFromGutenberg(innerHTML || '');

  return content ? (
    <p
      dangerouslySetInnerHTML={{
        __html: content || '',
      }}
      className={className}
    />
  ) : (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: html || '',
      }}
    />
  );
};

export default Paragraph;
